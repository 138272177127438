import { useState, useEffect } from 'react'

export const WINDOW_SIZE_XS = 0
export const WINDOW_SIZE_SM = 576
export const WINDOW_SIZE_MD = 768
export const WINDOW_SIZE_LG = 992
export const WINDOW_SIZE_XL = 1290
export const WINDOW_SIZE_XXL = 1440

function useWindowResize() {
  const [width, setWidth] = useState(window.innerWidth)
  const [height, setHeight] = useState(window.innerHeight)

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth)
      setHeight(window.innerHeight)
    }

    window.addEventListener('resize', handleResize)

    // Call immediately to set initial window size
    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const isSmallScreen = width < WINDOW_SIZE_SM
  const isMediumScreen = width >= WINDOW_SIZE_SM && width < WINDOW_SIZE_MD
  const isUpToMediumScreen = width < WINDOW_SIZE_MD
  const isLargeScreen = width >= WINDOW_SIZE_MD && width < WINDOW_SIZE_LG
  const isUpToLargeScreen = width < WINDOW_SIZE_LG
  const isXLScreen = width >= WINDOW_SIZE_LG
  const isXXLScreen = width >= WINDOW_SIZE_XXL

  return {
    width,
    height,
    isSmallScreen,
    isMediumScreen,
    isUpToMediumScreen,
    isLargeScreen,
    isUpToLargeScreen,
    isXLScreen,
    isXXLScreen,
  }
}

export default useWindowResize
