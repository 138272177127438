import Axios from 'axios'
import searchURI from 'services/searchURI'

const externalSync = {
  sources: {
    fetchAll() {
      return Axios.get('/api/admin/external_sync/sources')
    },

    fetch(id: string) {
      return Axios.get(`/api/admin/external_sync/sources/${id}`)
    },

    availableTypes() {
      return Axios.get('/api/admin/external_sync/sources/available_types')
    },

    destroy(sourceId: string) {
      return Axios.delete(`/api/admin/external_sync/sources/${sourceId}`)
    },

    resync(sourceId: string) {
      return Axios.post(`/api/admin/external_sync/sources/${sourceId}/resync`)
    },
  },

  documents: {
    fetchAll(sourceId: string, opts?: any) {
      const options = { page: 1, ...opts }

      return Axios.get(searchURI(`/api/admin/external_sync/sources/${sourceId}/documents`, options))
    },

    fetch(documentId: string) {
      return Axios.get(`/api/admin/external_sync/documents/${documentId}`)
    },

    fetchChildren(documentId: string) {
      return Axios.get(`/api/admin/external_sync/documents/${documentId}/children`)
    },

    import(sourceId: string, externalIds: string[]) {
      return Axios.post(`/api/admin/external_sync/sources/${sourceId}/documents/import`, { externalIds })
    },

    destroy(documentId: string) {
      return Axios.delete(`/api/admin/external_sync/documents/${documentId}`)
    },

    bulkDestroy(documentIds: string[]) {
      return Axios.delete('/api/admin/external_sync/documents/bulk_destroy', { data: { documentIds } })
    },
  },

  externalFiles: {
    search(sourceId: string, query: string) {
      return Axios.get(`/api/admin/external_sync/sources/${sourceId}/external_files/search`, { params: { q: query } })
    },
  },

  zendesk: {
    sources: {
      create(helpCenterUrl: string) {
        return Axios.post('/api/admin/external_sync/zendesk/sources', { helpCenterUrl })
      },
    },
  },
}

export default externalSync
