import Axios from 'axios'
import generateCrudRoutes from 'services/generateCrudRoutes'
import actions from './workflows/actions'
import runs from './workflows/runs'

export interface Workflow {
  id: string,
  name: string,
  description: string,
  displayPath: string,
}

export const buildWorkflowPayload = (workflow: Workflow) => _.pick(workflow, [
  'id',
  'name',
  'description',
])

const workflows = generateCrudRoutes(
  '/api/admin/workflows',
  'workflow',
  {},
  buildWorkflowPayload
)

export default {
  ...workflows,
  actions,
  runs,
  availableActions: {
    fetchAll() {
      return Axios.get('/api/admin/available_workflow_actions')
    },
  },
}
