import Axios from 'axios'

export const buildActionPayload = action => _.pick(action, [
  'id',
  'type',
  'settings',
  'configuration',
])

export default {
  create(workflowId, payload) {
    return Axios.post(`/api/admin/workflows/${workflowId}/actions`, { workflowAction: payload })
  },

  update(workflowId, actionId, payload) {
    if (_.isEmpty(payload.settings)) delete payload.settings
    if (_.isEmpty(payload.configuration)) delete payload.configuration

    return Axios.put(`/api/admin/workflows/${workflowId}/actions/${actionId}`, { workflowAction: payload })
  },

  destroy(workflowId, actionId) {
    return Axios.delete(`/api/admin/workflows/${workflowId}/actions/${actionId}`)
  },

  availableConfigurationInputs(workflowId, actionId) {
    return Axios.get(`/api/admin/workflows/${workflowId}/actions/${actionId}/available_configuration_inputs`)
  },
}
