import Axios from 'axios'

export default {
  fetchEngagement(params) {
    return Axios.post('/api/admin/analytics/engagement', params)
  },

  fetchAdoption(params) {
    return Axios.post('/api/admin/analytics/engagement/adoption', params)
  },

  fetchActivityCount(params) {
    return Axios.post('/api/admin/analytics/engagement/activity_count', params)
  },

  exportXlsx(params) {
    return Axios.post('/api/admin/analytics/engagement/export_xlsx', params)
  },

  exportAdoptionXlsx(params) {
    return Axios.post('/api/admin/analytics/engagement/export_adoption_xlsx', params)
  },

  exportMostUsedPagesXlsx(params) {
    return Axios.post('/api/admin/analytics/engagement/export_most_used_pages_xlsx', params)
  },
}
