import { ToolbarItem } from 'components/common/tiptap/toolbar/toolbar'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.tiptap.toolbar')

// small toolbar is used for the editor in profiles, groups
export const SMALL_TOOLBAR: ToolbarItem[] = [
  'bold',
  'italic',
  'underline',
  'emoji',
  'insertLink',
  'clearFormat',
  '|',
  'undo',
  'redo',
]

export const SMALL_TOOLBAR_WITH_ATTACHMENTS: ToolbarItem[] = [
  'insertFile',
  ...SMALL_TOOLBAR,
]

export const SLACK_TOOLBAR: ToolbarItem[] = [
  'bold',
  'italic',
  'emoji',
  'insertLink',
  'orderedList',
  'bulletList',
  'clearFormat',
  '|',
  'undo',
  'redo',
  '|',
  'insertImage',
]

// used in event icebreakers, event questions
export const MEDIUM_TOOLBAR: ToolbarItem[] = [
  'bold',
  'italic',
  'underline',
  {
    name: 'moreText',
    className: 'MoreTextButton',
    tooltipText: I18N('more_text'),
    iconPath: '/images/tiptap/more-text.svg',
    children: [
      'clearFormat',
      'textColor',
      'textBackgroundColor',
      'strikethrough',
      'subscript',
      'superscript',
    ],
  },
  'emoji',
  '|',
  'columnLayout',
  'textAlign',
  'orderedList',
  'bulletList',
  '|',
  'insertLink',
  'insertImage',
  'videoEmbed',
  'insertFile',
  'insertTable',
  'outdent',
  'indent',
  'lineHeight',
  'blockquote',
  'horizontalRule',
  'codeView',
  'undo',
  'redo',
]

// used in carousel cards
export const TEXT_ONLY_TOOLBAR: ToolbarItem[] = [
  'paragraphFormat',
  'fontSize',
  'bold',
  'italic',
  'underline',
  'textColor',
  'textAlign',
  'lineHeight',
  '|',
  'insertLink',
  '|',
  'emoji',
  '|',
  'horizontalRule',
  'codeView',
  'undo',
  'redo',
]

// used in celebrations
export const CELEBRATIONS_TOOLBAR: ToolbarItem[] = [
  'paragraphFormat',
  'fontSize',
  'bold',
  'italic',
  'underline',
  'textColor',
  'textBackgroundColor',
  'lineHeight',
  '|',
  'emoji',
  '|',
  'undo',
  'redo',
]

// used in articles
export const ARTICLES_TOOLBAR: ToolbarItem[] = [
  'bold',
  'italic',
  'underline',
  '|',
  {
    name: 'moreText',
    className: 'MoreTextButton',
    tooltipText: I18N('more_text'),
    iconPath: '/images/tiptap/more-text.svg',
    children: [
      'fontFamily',
      'fontSize',
      'textColor',
      'textBackgroundColor',
      'clearFormat',
      'strikethrough',
      'subscript',
      'superscript',
    ],
  },
  '|',
  'emoji',
  'columnLayout',
  {
    name: 'moreParagraph',
    className: 'MoreParagraphButton',
    tooltipText: I18N('more_paragraph'),
    iconPath: '/images/tiptap/more-paragraph.svg',
    children: [
      'paragraphFormat',
      'textAlign',
      'orderedList',
      'bulletList',
      'lineHeight',
      'indent',
      'outdent',
      'blockquote',
      'details',
    ],
  },
  '|',
  'insertLink',
  'insertImage',
  'videoEmbed',
  'insertFile',
  '|',
  'insertTable',
  'horizontalRule',
  'codeView',
  'socialShare',
  '|',
  'undo',
  'redo',
]

export const FULL_TOOLBAR: ToolbarItem[] = [
  'paragraphFormat',
  'fontFamily',
  'fontSize',
  'bold',
  'italic',
  'underline',
  {
    name: 'moreText',
    className: 'MoreTextButton',
    tooltipText: I18N('more_text'),
    iconPath: '/images/tiptap/more-text.svg',
    children: [
      'clearFormat',
      'textColor',
      'textBackgroundColor',
      'strikethrough',
      'subscript',
      'superscript',
    ],
  },
  '|',
  'textAlign',
  'orderedList',
  'bulletList',
  '|',
  'columnLayout',
  'insertLink',
  'insertImage',
  'videoEmbed',
  'embed',
  'insertFile',
  'emoji',
  'insertTable',
  'outdent',
  'indent',
  'lineHeight',
  'blockquote',
  'details',
  'horizontalRule',
  'codeView',
  'undo',
  'redo',
]

export default FULL_TOOLBAR
