import { useState, useCallback } from 'react'
import appSignal from 'services/appSignal'
import { checkForError, getResponseOrThrow } from 'utils/errorHandling'
import denormalizedJsonApiResponse from 'utils/denormalizedJsonApiResponse'
import queryParamsFromHeaders from 'utils/queryParamsFromHeaders'
import { useDispatch, useSelector, useStore } from 'react-redux'
import entitySlice from 'redux/slices/entities'
import { showToastMessage } from 'redux/slices/toasts'
import { ReduxState } from 'redux/redux'
import globalStorageSlice from 'redux/slices/globalStorage'
import collaborativeEditorSlice from 'redux/slices/collaborativeEditor'

export type ApiFunction = (...args: any[]) => Promise<any>

export interface Options {
  updateEntitySlice?: boolean
  addEntitySlice?: boolean
  onError?: (error: any) => void
  onSuccess?: (data: any, response?: any) => void
  onFinally?: () => void
  toastSuccessMessage?: string
  toastErrorMessage?: string
  globalStateBucket?: string
  broadcastRecordChanges?: boolean
  defaultIsLoading?: boolean
}

export type PaginationData = Partial<{
  page: number
  perPage: number
  total: number
  totalPages: number
}>
export interface ApiHookReturn<T = any> {
  isLoading: boolean;
  isLoaded: boolean;
  isNotFound: boolean;
  data: T | undefined;
  meta: any;
  error: any;
  paginationData: PaginationData
}

const getData = (response) => {
  if (Array.isArray(response?.data?.data)) {
    if (response?.data?.data.length === 0) {
      return []
    } else {
      return denormalizedJsonApiResponse(response, _.camelCase(response?.data?.data[0].type))
    }
  } else if (response?.data?.data?.type) {
    return denormalizedJsonApiResponse(response, _.camelCase(response?.data?.data?.type))
  } else {
    return response?.data
  }
}
const getPaginationData = (response): PaginationData => (
  (response?.headers && (response.headers['x-page'] || response.headers['x-total'])) ? queryParamsFromHeaders(response) : {}
)

// if globalStateBucket is passed, the data will be stored in the globalStorageSlice
// otherwise just fallback for local state
function useLocalOrGlobalState<T>(key: string, defaultValue: T, globalStateBucket?: string): [T, (value: T) => void] {
  const [localState, setLocalState] = useState<T>(defaultValue)

  const reduxStore = useStore<ReduxState>()
  const dispatch = useDispatch()
  const store: Record<string, T> | null = useSelector(
    (state: ReduxState) => (globalStateBucket ? state.globalStorage[globalStateBucket] : null)
  )
  const globalValue = store && key in store ? store[key] : defaultValue

  const setGlobalState = (value: T) => {
    const state = reduxStore.getState()

    dispatch(
      globalStorageSlice.actions.store({
        globalStateBucket,
        data: {
          ...state.globalStorage[globalStateBucket!],
          [key]: value,
        },
      })
    )
  }

  return globalStateBucket ? [globalValue, setGlobalState] : [localState, setLocalState]
}

const useApi = (apiFunction: ApiFunction, options: Options = {}): [ApiFunction, ApiHookReturn] => {
  const dispatch = useDispatch()

  const { globalStateBucket, defaultIsLoading = false } = options

  const [isLoading, setIsLoading] = useLocalOrGlobalState('isLoading', defaultIsLoading, globalStateBucket)
  const [isLoaded, setIsLoaded] = useLocalOrGlobalState('isLoaded', false, globalStateBucket)
  const [data, setData] = useLocalOrGlobalState<any>('data', undefined, globalStateBucket)
  const [meta, setMeta] = useLocalOrGlobalState<any>('meta', undefined, globalStateBucket)
  const [paginationData, setPaginationData] = useLocalOrGlobalState<PaginationData>('paginationData', {}, globalStateBucket)
  const [error, setError] = useLocalOrGlobalState<any>('error', undefined, globalStateBucket)

  const callApi = useCallback(async (...params) => {
    try {
      setIsLoading(true)
      setError(undefined)
      setPaginationData({})

      const response = await apiFunction(...params)
      const data = getData(response)

      setData(data)
      setMeta(response?.data?.meta)
      setPaginationData(getPaginationData(response))
      setIsLoading(false)
      setIsLoaded(true)

      if (options.updateEntitySlice) {
        dispatch(entitySlice.actions.update({ data: response.data }))
      }

      if (options.addEntitySlice) {
        dispatch(entitySlice.actions.add({ data: response.data }))
      }

      if (options.toastSuccessMessage) { dispatch(showToastMessage({ message: options.toastSuccessMessage, type: 'success' })) }
      if (options.onSuccess) { options.onSuccess(data, response) }

      if (options.broadcastRecordChanges) {
        dispatch(collaborativeEditorSlice.asyncActions.broadcastRecordChanges(response.data))
      }

      return data
    } catch (e) {
      if (options.toastErrorMessage) { dispatch(showToastMessage({ message: options.toastErrorMessage, type: 'error' })) }
      appSignal.sendErrorUnlessClearyBackendError(e)
      const { error } = checkForError(getResponseOrThrow(e))
      setError(error)
      setIsLoading(false)
      setData(undefined)
      if (options.onError) { options.onError(error) }
    } finally {
      if (options.onFinally) { options.onFinally() }
    }

    return null
  }, [apiFunction, options])

  return [callApi, {
    isLoading,
    isLoaded,
    data,
    meta,
    error,
    paginationData,
    isNotFound: ['not_found', 'unauthorized'].includes(error?.status),
  }]
}

export default useApi
