import React, { useState } from 'react'
import { Button as BootstrapButton } from 'react-bootstrap'
import LoadingSpinnerText from 'components/common/loadingSpinnerText'
import ConfirmationModal from 'components/common/modals/confirmationModal'
import { I18NCommon } from 'utils/i18nHelpers'

export interface ConfirmProps {
  title?: string | React.ReactNode,
  description?: string,
  variant?: 'danger' | 'warning' | 'info' | 'success',
}
export interface ButtonProps {
  className?: string
  id?: string
  style?: Record<string, any>
  disabled?: boolean
  type?: string
  onClick?: (e: any) => void
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  dataTestId?: string
  children?: any
  variant?: string
  showLoadingSpinner?: boolean
  size?: 'sm' | 'lg' | undefined
  confirm?: boolean | ConfirmProps
}

export const extractConfirmationProps = (confirm: boolean | ConfirmProps) => {
  const defaultConfirmProps = {
    title: I18NCommon('confirmation_modal.title'),
    description: I18NCommon('confirmation_modal.description'),
  }

  if (typeof confirm === 'boolean') {
    return defaultConfirmProps
  } else {
    return { ...defaultConfirmProps, ...confirm }
  }
}

export const useConfirmationModal = (confirm: boolean | ConfirmProps, onClick?: (e: any) => void) => {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)

  const handleClick = (e) => {
    if (confirm) {
      setIsConfirmationModalOpen(true)
    } else {
      onClick?.(e)
    }
  }

  return { isConfirmationModalOpen, setIsConfirmationModalOpen, handleClick }
}

const Button = React.forwardRef<any, ButtonProps>(({
  className,
  id,
  style,
  disabled,
  type,
  onClick,
  onMouseEnter,
  onMouseLeave,
  dataTestId,
  children,
  variant = 'primary',
  showLoadingSpinner = false,
  confirm = false,
}, ref) => {
  const {
    isConfirmationModalOpen,
    setIsConfirmationModalOpen,
    handleClick,
  } = useConfirmationModal(confirm, onClick)

  return (
    <>
      <BootstrapButton
        data-testid={dataTestId}
        className={className}
        variant={variant}
        id={id}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        style={{ ...style, fontSize: '1rem' }}
        onClick={handleClick}
        disabled={disabled}
        type={type} // Submit
        ref={ref}
      >
        <LoadingSpinnerText showLoadingSpinner={showLoadingSpinner}>{children}</LoadingSpinnerText>
      </BootstrapButton>

      {isConfirmationModalOpen && (
        <ConfirmationModal
          visible
          toggle={() => setIsConfirmationModalOpen(!isConfirmationModalOpen)}
          onConfirmClick={(e) => {
            onClick?.(e)
            setIsConfirmationModalOpen(false)
          }}
          {...extractConfirmationProps(confirm)}
        />
      )}
    </>
  )
})

const ButtonNarrow = ({
  className,
  id,
  style,
  disabled,
  type,
  onClick,
  variant,
  children,
  showLoadingSpinner = false,
  onMouseEnter,
  onMouseLeave,
  confirm = false,
}: ButtonProps) => {
  const {
    isConfirmationModalOpen,
    setIsConfirmationModalOpen,
    handleClick,
  } = useConfirmationModal(confirm, onClick)

  return (
    <>
      <Button
        id={id}
        style={{ minWidth: '100px', ...style }}
        className={className}
        onClick={handleClick}
        disabled={disabled}
        type={type}
        variant={variant}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <LoadingSpinnerText showLoadingSpinner={showLoadingSpinner}>{children}</LoadingSpinnerText>
      </Button>

      {isConfirmationModalOpen && (
        <ConfirmationModal
          visible
          toggle={() => setIsConfirmationModalOpen(!isConfirmationModalOpen)}
          onConfirmClick={(e) => {
            onClick?.(e)
            setIsConfirmationModalOpen(false)
          }}
          {...extractConfirmationProps(confirm)}
        />
      )}
    </>
  )
}

const ButtonSecondary = React.forwardRef<any, ButtonProps>(({
  className, id, disabled, type, size, onClick, children, showLoadingSpinner = false,
}, ref) => (
  <BootstrapButton
    id={id}
    className={className}
    style={{ fontSize: '1rem' }}
    onClick={onClick}
    disabled={disabled}
    variant='secondary'
    type={type} // Submit
    size={size}
    ref={ref}
  >
    <LoadingSpinnerText showLoadingSpinner={showLoadingSpinner}>
      {children}
    </LoadingSpinnerText>
  </BootstrapButton>
))

export { Button, ButtonNarrow, ButtonSecondary }
