import type CorrespondentType from 'types/journeys/correspondent'
import normalizeTargetingRules from 'utils/normalizeTargetingRules'

import API from 'services/api'
import { createSlice } from '@reduxjs/toolkit'
import appSignal from 'services/appSignal'

export const initialState = {
  availableTemplates: [],
  meta: {
    isLoadingTemplates: false,
  },
}

export const STEP_TEMPLATE_ATTRIBUTES = [
  'state',
  'triggerTime',
  'triggerAfterNumDays',
  'triggerMilestone',
  'triggerImmediatelyIfPastTrigger',
  'triggerIfVariablesMissing',
  'triggerTimezone',
  'targetingRules',
  'parentId',
  'weekendTriggerShift',
]

export const NON_REQUIRED_STEP_TEMPLATE_ATTRIBUTES = [
  'triggerAfterNumDays',
  'triggerImmediatelyIfPastTrigger',
  'triggerIfVariablesMissing',
  'parentId',
  'triggerTimezone',
  'weekendTriggerShift',
  // These are added to handle custom steps, but are not meant to pass on to the backend
  'isCustom',
  'forUser',
  'isTaskable', // Added to know which are taskable steps, but not meant to pass on to the backend
  'rangeStartMilestone',
  'rangeEndMilestone',
  'scheduleSpecificallyMilestone',
  'rangeStartMilestoneInitialState',
  'rangeEndMilestoneInitialState',
  'scheduleSpecificallyMilestoneInitialState',
]

export const CORRESPONDENT_ATTRIBUTES = [
  'fromCorrespondentAttributes',
  'toCorrespondentAttributes',
  'ccCorrespondentsAttributes',
]

export const buildStepTemplatePayload = (stepTemplate) => {
  const stepTemplateAttrs = _.pick(stepTemplate, [...STEP_TEMPLATE_ATTRIBUTES, 'id', 'name', 'journeyBlueprintId'])

  if (stepTemplate.fromCorrespondentAttributes) {
    stepTemplateAttrs.fromCorrespondentAttributes = buildCorrespondentPayload(stepTemplate.fromCorrespondentAttributes)
  }

  if (stepTemplate.toCorrespondentAttributes) {
    stepTemplateAttrs.toCorrespondentAttributes = buildCorrespondentPayload(stepTemplate.toCorrespondentAttributes)
  }

  if (stepTemplate.ccCorrespondentsAttributes) {
    // eslint-disable-next-line max-len
    stepTemplateAttrs.ccCorrespondentsAttributes = stepTemplate.ccCorrespondentsAttributes.map(buildCorrespondentPayload)
  }

  if (stepTemplate.targetingRules) {
    stepTemplateAttrs.targetingRules = normalizeTargetingRules(stepTemplate.targetingRules)
  }

  return stepTemplateAttrs
}

export const buildCorrespondentPayload = (correspondent) => {
  const correspondentAttrs = _.pick(correspondent, ['id', 'role', 'userId', 'email', 'groupId'])

  if (correspondent.user && !correspondentAttrs.userId) {
    correspondentAttrs.userId = correspondent.user.id
  }

  if (correspondent.group && !correspondentAttrs.groupId) {
    correspondentAttrs.groupId = correspondent.group.id
  }

  return correspondentAttrs
}

const stepTemplateSlice = createSlice({
  name: 'stepTemplates',
  initialState,
  reducers: {
    setAvailableTemplates(state, action) {
      state.availableTemplates = action.payload
    },

    isLoadingTemplates(state, action) {
      state.meta.isLoadingTemplates = action.payload
    },
  },
})


const asyncActions = {
  admin: {
    fetchAvailableTemplates: (params = {}) => async (dispatch) => {
      dispatch(stepTemplateSlice.actions.isLoadingTemplates(true))

      try {
        const response = await API.admin.stepTemplates.fetchAvailableTemplates(params)

        dispatch(stepTemplateSlice.actions.setAvailableTemplates(response.data.availableTemplates))
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
      } finally {
        dispatch(stepTemplateSlice.actions.isLoadingTemplates(false))
      }
    },
  },
}

const selectors = {
  getAvailableTemplates: () => state => state.stepTemplates.availableTemplates || [],
  getMetadata: () => state => state.stepTemplates.meta,
}

export default {
  ...stepTemplateSlice,
  selectors,
  asyncActions,
}
