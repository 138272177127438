import Axios from 'axios'
import generateCrudRoutes from 'services/generateCrudRoutes'
import searchURI from 'services/searchURI'

const buildTicketPayload = ticket => _.pick(ticket, [
  'id',
  'title',
  'userId',
  'assigneeId',
  'dueDate',
  'description',
  'origin',
])

const buildMessagePayload = message => _.pick(message, [
  'id',
  'content',
])

const ticketing = {
  tickets: generateCrudRoutes('/api/ticketing/tickets', 'ticket', {}, buildTicketPayload),
  messages: {
    fetchAll(ticketId, opts) {
      const options = { page: 1, ...opts }

      return Axios.get(searchURI(`/api/ticketing/tickets/${ticketId}/messages`, options))
    },

    create(ticketId, message) {
      return Axios.post(`/api/ticketing/tickets/${ticketId}/messages`, { message: buildMessagePayload(message) })
    },
  },
  ticketActivities: {
    fetchAll(ticketId) {
      return Axios.get(`/api/ticketing/tickets/${ticketId}/ticket_activities`)
    },
  },
}

export default ticketing
