import React, {
  useEffect, useMemo, useRef, useState
} from 'react'
import { createLowlight, common } from 'lowlight'
import { useEditor, EditorContent } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import FontFamily from '@tiptap/extension-font-family'
import Underline from '@tiptap/extension-underline'
import Color from '@tiptap/extension-color'
import Subscript from '@tiptap/extension-subscript'
import Superscript from '@tiptap/extension-superscript'
import ClearyMention from 'components/common/tiptap/extensions/clearyMention'
import TextAlign from '@tiptap/extension-text-align'
import Collaboration from '@tiptap/extension-collaboration'
import CollaborationCursor from '@tiptap/extension-collaboration-cursor'
import ClearyTable from 'components/common/tiptap/extensions/clearyTable'
import createRightClickExtension from 'components/common/tiptap/extensions/clearyRightClick'
import TableRow from '@tiptap/extension-table-row'
import Details from '@tiptap-pro/extension-details'
import DetailsSummary from '@tiptap-pro/extension-details-summary'
import DetailsContent from '@tiptap-pro/extension-details-content'
import CharacterCount from '@tiptap/extension-character-count'
import ClearyLink from 'components/common/tiptap/extensions/clearyLink'
import ClearyTableCell from 'components/common/tiptap/extensions/clearyTableCell'
import ClearyTableHeader from 'components/common/tiptap/extensions/clearyTableHeader'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import Toolbar, { ToolbarItem } from 'components/common/tiptap/toolbar/toolbar'
import { FULL_TOOLBAR } from 'components/common/tiptap/toolbar/toolbarVariations'
import ClearyTextStyle from 'components/common/tiptap/extensions/clearyTextStyle'
import BackgroundColor from 'components/common/tiptap/extensions/backgroundColor'
import mentionSuggestion from 'components/common/tiptap/suggestions/mentions/mentionSuggestion'
import { ClearyBlockImage, ClearyInlineImage } from 'components/common/tiptap/extensions/clearyImage'
import useCollaborativeEditing from 'components/common/tiptap/hooks/useCollaborativeEditing'
import ClearyBubbleMenu from 'components/common/tiptap/menus/bubbleMenu'
import { ClearyBlockEmbed, ClearyInlineEmbed } from 'components/common/tiptap/extensions/embed'
import handleTiptapEditorPaste from 'components/common/tiptap/handlers/handleTiptapEditorPaste'
import LineHeight from 'components/common/tiptap/extensions/lineHeight'
import classNames from 'classnames'
import TiptapEditorSourceCodeView, { EDIT_VIEW, SOURCE_CODE_VIEW } from 'components/common/tiptap/tiptapEditorSourceCodeView'
import { ClearyBulletList, ClearyOrderedList } from 'components/common/tiptap/extensions/clearyLists'
import Emoji from 'components/common/tiptap/extensions/emoji'
import SlashCommand from 'components/common/tiptap/extensions/slashCommand'
import Indent from 'components/common/tiptap/extensions/indent'
import PasteHelper from 'components/common/tiptap/extensions/pasteHelper'
import transformPastedHtml from 'components/common/tiptap/handlers/transformPastedHtml'
import CaptureEventExtension from 'components/common/tiptap/extensions/captureEvent'
import handleTiptapEditorDrop from 'components/common/tiptap/handlers/handleTiptapEditorDrop'
import AddCommentBubbleMenu from 'components/common/tiptap/menus/comments/addCommentBubbleMenu'
import inlineComment from 'components/common/tiptap/extensions/inlineComment'
import RichTextInlineComments from 'components/common/tiptap/richTextInlineComments/richTextInlineComments'
import ClearyBold from 'components/common/tiptap/extensions/clearyBold'
import ClearyHeading from 'components/common/tiptap/extensions/clearyHeading'
import ClearyParagraph from 'components/common/tiptap/extensions/clearyParagraph'
import ClearyPlaceholder from 'components/common/tiptap/extensions/clearyPlaceholder'
import randomPlaceholder from 'components/common/tiptap/utils/randomPlaceholder'
import {
  ySyncPluginKey
} from 'y-prosemirror'
import moment from 'moment'
import Typography from '@tiptap/extension-typography'
import ClearyBackspace from 'components/common/tiptap/extensions/clearyBackspace'
import useTransactionLatencyMonitoring from 'components/common/tiptap/hooks/useTransactionLatencyMonitoring'
import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight'
import { ClearyBlockVideo, ClearyInlineVideo } from 'components/common/tiptap/extensions/video'
import { useDispatch } from 'react-redux'
import { AiTextGeneration, AiPrompt, AiBlock } from 'components/common/tiptap/extensions/aiNodes'

import { DEFAULT_CONFIGURATION, ConfigSetting } from 'components/common/tiptap/configurations'
import { uniqueId } from 'lodash'
import ContextMenu from 'components/common/tiptap/menus/contextMenu'
import { present } from 'components/common/utils'
import { SocialShare } from 'components/common/tiptap/extensions/socialShareNodes'
import useForwardToLatestCallback, { useForwardObjectToLatestCallback } from 'components/common/hooks/useForwardToLatestCallback'

const lowlight = createLowlight(common)

interface Props {
  html?: string | null
  onChange?: (html: string) => void
  toolbarItems?: ToolbarItem[]
  provider?: any // only used for CollaborativeEditor
  configuration?: Partial<Record<ConfigSetting, any>>
  className?: string
  editorContentClassName?: string
  richTextId?: string
  handleKeyDown?: any
  editorRef?: any
  handleDOMEvents?: any
}

const TEXT_ALIGN_TYPES = [
  'heading',
  'paragraph',
  'inlineImage',
  'blockImage',
  'blockEmbed',
  'inlineEmbed',
  'inlineVideo',
  'blockVideo',
]

const TiptapEditor = ({
  html,
  onChange = () => {},
  richTextId,
  toolbarItems = FULL_TOOLBAR,
  provider = null,
  configuration = DEFAULT_CONFIGURATION,
  className = '',
  editorContentClassName,
  handleKeyDown: handleKeyDownProp = () => false,
  editorRef,
  handleDOMEvents: handleDOMEventsProp = {},
}: Props) => {
  const dispatch = useDispatch()
  const isCollaborative = !!provider

  const handleDOMEvents = useForwardObjectToLatestCallback(handleDOMEventsProp)
  const handleKeyDown = useForwardToLatestCallback(handleKeyDownProp)

  const editorUniqueId = useMemo(() => uniqueId('tiptap_editor_'), [])

  const [currentView, setCurrentView] = useState(EDIT_VIEW)
  const isSourceCodeViewEnabled = currentView === SOURCE_CODE_VIEW

  const saveTransactionLatency = useTransactionLatencyMonitoring(isCollaborative)
  const configWithDefaults = { ...DEFAULT_CONFIGURATION, ...configuration }

  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false)

  const editor = useEditor({
    // https://tiptap.dev/blog/release-notes/say-hello-to-tiptap-2-5-our-most-performant-editor-yet
    immediatelyRender: true,
    content: html,
    extensions: [
      StarterKit.configure({
        // Collaboration uses its own version of history, so we must disable it
        history: isCollaborative ? false : { depth: 100, newGroupDelay: 500 },
        bulletList: false,
        orderedList: false,
        bold: false,
        heading: false,
        paragraph: false,
        codeBlock: false,
      }),
      createRightClickExtension(setIsContextMenuOpen),
      ClearyBold,
      ClearyHeading,
      ClearyParagraph,
      ClearyBulletList,
      ClearyOrderedList,
      ...isCollaborative ? (
        [
          Collaboration.configure({ document: provider.document }),
          CollaborationCursor.configure({ provider }),
        ]
      ) : (
        []
      ),
      FontFamily,
      Underline,
      Color,
      PasteHelper,
      Subscript,
      Superscript,
      TextAlign.configure({
        types: TEXT_ALIGN_TYPES,
      }),
      ClearyLink.configure({
        openOnClick: false,
        autolink: true,
      }),
      ClearyTextStyle,
      ...configWithDefaults.userMentionsEnabled ? (
        [
          ClearyMention.configure({
            HTMLAttributes: {
              class: 'link-color',
            },
            renderLabel({ options, node }) {
              return `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}`
            },
            suggestion: mentionSuggestion,
          }),
        ]
      ) : ([]),
      BackgroundColor,
      ...configWithDefaults.embedsEnabled ? (
        [
          ClearyBlockEmbed,
          ClearyInlineEmbed,
        ]
      ) : ([]),
      ...configWithDefaults.imagesEnabled ? (
        [
          ClearyInlineImage,
          ClearyBlockImage,
        ]
      ) : ([]),
      LineHeight,
      Indent,
      ClearyTable,
      ClearyTableCell,
      ClearyTableHeader,
      TableRow,
      Emoji,
      ...configWithDefaults.slashCommandEnabled ? (
        [
          SlashCommand.configure({
            aiEnabled: configWithDefaults.aiEnabled,
            socialShareEnabled: configWithDefaults.socialShareEnabled,
          }),
        ]
      ) : ([]),
      CaptureEventExtension,
      ClearyBackspace,
      inlineComment,
      CodeBlockLowlight.configure({
        lowlight,
      }),
      ClearyPlaceholder.configure({
        placeholder: configWithDefaults.placeholder || randomPlaceholder(),
      }),
      Typography.configure({
        notEqual: false,
        oneHalf: false,
        oneQuarter: false,
        threeQuarters: false,
      }),
      Details.configure({
        persist: true,
        HTMLAttributes: {
          class: 'details',
        },
      }),
      DetailsSummary,
      DetailsContent,
      ...configWithDefaults.videosEnabled ? ([
        ClearyInlineVideo,
        ClearyBlockVideo,
      ]) : ([]),
      ...configWithDefaults.aiEnabled ? [AiPrompt, AiTextGeneration, AiBlock] : [],
      SocialShare,
      CharacterCount.configure({
        limit: configWithDefaults.characterLimit,
      }),
    ],
    onUpdate: ({ editor, transaction }) => {
      saveTransactionLatency(transaction)
      onChange(editor.getHTML())
    },
    onTransaction: ({ transaction }) => {
      // Make sure we update the last contributed at time when only on our own changes
      // ySyncPluginKey is the plugin hocuspocus uses to sync changes and it adds a isChangeOrigin meta property to the transaction
      // isChangeOrigin means the change happened in the server (another user made the change)
      if (isCollaborative && transaction.docChanged && !transaction.getMeta(ySyncPluginKey)?.isChangeOrigin) {
        // Add awareness update of the last contributed at time
        provider?.awareness?.setLocalStateField('lastContributedAt', moment().toISOString())
      }
    },
    editorProps: {
      attributes: {
        editorUniqueId,
        spellcheck: 'true',
        richTextId: richTextId?.toString() ?? '',
        videosEnabled: configWithDefaults.videosEnabled, // Prosemirror processes this as a boolean, no need to make it a string
        isZoomEnabled: configWithDefaults.isZoomEnabled,
        isEditingTemplate: configWithDefaults.isEditingTemplate,
      },
      // TODO: always pass dispatch when the video uploader feature is finished and remove the ternary operator
      // eslint-disable-next-line max-len
      handlePaste: (view, event, slice) => (
        handleTiptapEditorPaste(
          view,
          event,
          slice,
          richTextId,
          configWithDefaults.videosEnabled ? dispatch : null,
          configWithDefaults
        )
      ),
      transformPastedHTML: (html, _view) => transformPastedHtml(html),
      // TODO: always pass dispatch when the video uploader feature is finished and remove the ternary operator
      // eslint-disable-next-line max-len
      handleDrop: (view, event, slice, moved) => (
        handleTiptapEditorDrop(
          view,
          event,
          slice,
          moved,
          richTextId,
          configWithDefaults.videosEnabled ? dispatch : null,
          configWithDefaults
        )
      ),
      handleKeyDown,
      handleDOMEvents,
    },
  })

  useCollaborativeEditing(isCollaborative, editor)

  useEffect(() => {
    if (editor && configWithDefaults.focusOnInitialize) {
      editor.chain().focus().run()
    }

    if (editorRef && editor) {
      editorRef.current = editor
    }
  }, [editor])

  const commentsEnabled = editor && richTextId && configWithDefaults.commentsEnabled

  // using memo to avoid this creating a new array each time.
  // the config and toolbarItems should never change in an instance of the editor ()
  const filteredToolbarItems = useMemo(() => {
    let items = [
      configWithDefaults.aiEnabled ? 'aiAssistant' : null,
      ...toolbarItems,
    ]

    if (!configWithDefaults.socialShareEnabled) {
      // remove socialShare
      items = items.filter(item => item !== 'socialShare')
    }

    return items.filter(present) as ToolbarItem[]
  }, [toolbarItems])

  if (!editor) {
    return <CirclesLoadingIndicator />
  }

  return (
    <div className={classNames('TiptapEditor d-flex flex-column', className)}>
      {!_.isEmpty(filteredToolbarItems) && (
        <Toolbar
          editor={editor}
          toolbarItems={filteredToolbarItems}
          isSourceCodeViewEnabled={isSourceCodeViewEnabled}
          setCurrentView={setCurrentView}
          stickyToolbar={configWithDefaults.stickyToolbar}
        />
      )}
      <div className='TiptapEditorContentWrapper d-flex flex-grow-1'>
        <div className='TiptapEditorContent d-flex flex-column flex-grow-1'>
          <TiptapEditorSourceCodeView
            currentView={currentView}
            initialCode={editor.getHTML()}
            onCodeChange={html => editor.commands.setContent(html)}
          />
          <EditorContent
            className={classNames('TiptapView', editorContentClassName, { isSourceCodeViewEnabled })}
            editor={editor}
            onContextMenu={(e) => {
              if (isContextMenuOpen) e.preventDefault()
            }}
          />
          <div className='flex-grow-1' onClick={() => editor.chain().focus().run()} />
        </div>
        {commentsEnabled && <RichTextInlineComments richTextId={richTextId} />}
      </div>
      {editor && <ClearyBubbleMenu editor={editor} />}
      {editor && (
        <ContextMenu
          editor={editor}
          isOpen={isContextMenuOpen}
          onClose={() => setIsContextMenuOpen(false)}
        />
      )}
      {commentsEnabled && <AddCommentBubbleMenu editor={editor} richTextId={richTextId} />}
    </div>
  )
}

export default TiptapEditor
