import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'components/common/buttons/button'
import LoadingSpinnerText from 'components/common/loadingSpinnerText'

interface ButtonLinkProps {
  children: React.ReactNode
  to?: string
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void
  className?: string
  style?: React.CSSProperties
  variant?: string
  showLoadingSpinner?: boolean
  target?: string
  size?: 'sm' | 'lg' | undefined
  Component?: React.ComponentType<any>
}

const ButtonLink = ({
  children,
  to = '',
  onClick = (e) => {},
  className = '',
  style = {},
  variant = 'primary', showLoadingSpinner = false, target = '',
  size,
  Component = Button,
}: ButtonLinkProps) => (
  <Link to={to} onClick={onClick} className={className} target={target}>
    <Component variant={variant} style={style} size={size}>
      <LoadingSpinnerText showLoadingSpinner={showLoadingSpinner}>
        {children}
      </LoadingSpinnerText>
    </Component>
  </Link>
)

// eslint-disable-next-line import/prefer-default-export
export { ButtonLink }
