import Axios from 'axios'
import generateCrudRoutes from 'services/generateCrudRoutes'
import searchURI from 'services/searchURI'

const buildTicketPayload = ticket => _.pick(ticket, [
  'id',
  'title',
  'userId',
  'assigneeId',
  'dueDate',
  'description',
  'origin',
])

const buildMessagePayload = message => _.pick(message, [
  'id',
  'content',
])

export type SuggestedMessageType = {
  id: string
  content: string
}

const adminTicketing = {
  tickets: {
    ...generateCrudRoutes('/api/admin/ticketing/tickets', 'ticket', {}, buildTicketPayload),

    assign(ticketId, assigneeId) {
      return Axios.patch(`/api/admin/ticketing/tickets/${ticketId}/assign`, { assigneeId })
    },

    close(ticketId) {
      return Axios.patch(`/api/admin/ticketing/tickets/${ticketId}/close`)
    },

    reopen(ticketId) {
      return Axios.patch(`/api/admin/ticketing/tickets/${ticketId}/reopen`)
    },

    counts() {
      return Axios.get('/api/admin/ticketing/tickets/counts')
    },

    suggestedMessage: {
      fetch(ticketId) {
        return Axios.get(`/api/ticketing/tickets/${ticketId}/current_suggested_message`)
      },
      sendReply(ticketId) {
        return Axios.post(`/api/ticketing/tickets/${ticketId}/current_suggested_message/send_reply`)
      },
      editReply(ticketId) {
        return Axios.put(`/api/ticketing/tickets/${ticketId}/current_suggested_message/edit_reply`)
      },
    },
  },
  messages: {
    fetchAll(ticketId, opts) {
      const options = { page: 1, ...opts }

      return Axios.get(searchURI(`/api/admin/ticketing/tickets/${ticketId}/messages`, options))
    },

    create(ticketId, message) {
      return Axios.post(`/api/admin/ticketing/tickets/${ticketId}/messages`, { message: buildMessagePayload(message) })
    },
  },
  ticketActivities: {
    fetchAll(ticketId) {
      return Axios.get(`/api/admin/ticketing/tickets/${ticketId}/ticket_activities`)
    },
  },
}

export default adminTicketing
