import { useEffect, RefObject } from 'react'

const isElementClicked = (target: HTMLElement, testElement: Element) => {
  let currentTarget: HTMLElement | null = target

  while (currentTarget) {
    if (currentTarget === testElement) return true
    currentTarget = currentTarget.parentElement
  }

  return false
}

type UseClickOutsideProps = {
  ref: RefObject<HTMLElement>
  onClickOutside: () => void
  enableClickOutside: boolean
  eventType?: 'click' | 'mousedown'
  ignoredSelectors?: string[]
}

const useClickOutside = ({
  ref,
  onClickOutside,
  enableClickOutside,
  eventType = 'click',
  ignoredSelectors = [],
}: UseClickOutsideProps) => {
  useEffect(() => {
    if (!enableClickOutside) return () => {}

    const handleClickOutside = (e) => {
      // if the clicked element was just removed, it'll act as click outside.
      // unfortunately, we can't determine if it was clicked outside or not
      // so let's just ignore it
      const isClickedElementTheDocumentHtml = e.target === document.documentElement
      const isClickedElementInTheDom = document.body.contains(e.target) || isClickedElementTheDocumentHtml

      const ignoredElements = ignoredSelectors.flatMap(selector => Array.from(document.querySelectorAll(selector)))
      const isClickInIgnoredElement = ignoredElements.some(element => isElementClicked(e.target, element))

      if (ref.current && !ref.current.contains(e.target) && isClickedElementInTheDom && !isClickInIgnoredElement) {
        onClickOutside()
      }
    }
    // the timeout fix this case:
    // we click in a button
    // display a the component that uses this hook
    // the click event is attached to the document
    // but the callback is trigerred anyway
    // we detect we clicked outside wrongly
    setTimeout(() => {
      document.addEventListener(eventType, handleClickOutside)
    }, 0)

    return () => {
      document.removeEventListener(eventType, handleClickOutside)
    }
  }, [enableClickOutside])
}

export default useClickOutside
