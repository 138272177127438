import Axios from 'axios'

export interface Triggerable {
  id: string
  user: any
}

export interface Action {
  id: string
  name: string
}

export interface ActionRun {
  id: string
  action: Action
  output: any
  status: string
  createdAt: string
  updatedAt: string
}

export interface WorkflowRun {
  id: string
  status: string
  triggerableType: string
  triggerable: Triggerable
  createdAt: string
  updatedAt: string
  actionRuns: ActionRun[]
}

export default {
  fetchAll(workflowId, params) {
    return Axios.get(`/api/admin/workflows/${workflowId}/runs`, { params })
  },

  fetch(workflowId, runId) {
    return Axios.get(`/api/admin/workflows/${workflowId}/runs/${runId}`)
  },
}
