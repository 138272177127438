import Axios from 'axios'
import generateCrudRoutes from 'services/generateCrudRoutes'

const userBillingAccessLevelSnapshots = {
  ...generateCrudRoutes(
    '/api/admin/user_billing_access_level_snapshots',
    'user_billing_access_level_snapshot'
  ),

  exportXlsx(params) {
    return Axios.post(`/api/admin/user_billing_access_level_snapshots/${params.id}/export_xlsx`, params)
  },
}

export default userBillingAccessLevelSnapshots
